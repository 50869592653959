import React from 'react';
import Content from './Content';
import ReactGA from 'react-ga';
import SidebarLayout from '../components/SidebarLayout';
import { initAnalytics } from '../initAnalytics';

export default class PreviewPage extends React.Component {

  componentDidMount() {
    if (typeof window !== `undefined`) {
      initAnalytics();
      ReactGA.pageview('/preview');
    }
  }

  render () {
    return (
      <SidebarLayout>
        <Content />
      </SidebarLayout>
    );
  }
}

